import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { graphql } from "gatsby";
import queryString from "query-string";

const IndexPage = (props) => {
  const { search } = props.location;
  const params = search ? queryString.parse(search) : {};
  const { edges } = props.data.allMarkdownRemark;
  const Posts = edges
    .filter((edge) => edge.node.frontmatter.tags.includes(params.tag))
    .map((edge) => (
      <div key={edge.node.id}>
        <Link to={edge.node.frontmatter.path}>
          {edge.node.frontmatter.title}
        </Link>
      </div>
    ));

  return (
    <Layout>
      <SEO title="Sorted Coder" />
      {Posts}
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: ASC, fields: [frontmatter___concept] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
            draft
            tags
          }
        }
      }
    }
  }
`;
